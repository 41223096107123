import { FunctionComponent } from "react";
import styles from "./GroupComponent3.module.css";
const GroupComponent3: FunctionComponent = () => {
  return (
    <div className={styles.vectorParent}>
      <img className={styles.vectorIcon} alt="" src="/vector7.svg" />
      <b className={styles.launchintro}>LaunchIntro</b>
    </div>
  );
};

export default GroupComponent3;
