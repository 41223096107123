import { FunctionComponent, useState, useCallback } from "react";
import MobileMenuAccount from "./MobileMenuAccount";
import PortalDrawer from "./PortalDrawer";
import { useNavigate } from "react-router-dom";
import styles from "./Header.module.css";
const Header: FunctionComponent = () => {
  const [isMobileMenuAccountOpen, setMobileMenuAccountOpen] = useState(false);
  const navigate = useNavigate();

  const openMobileMenuAccount = useCallback(() => {
    setMobileMenuAccountOpen(true);
  }, []);

  const closeMobileMenuAccount = useCallback(() => {
    setMobileMenuAccountOpen(false);
  }, []);

  const onTabDashboardContainerClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onTabToursContainerClick = useCallback(() => {
    navigate("/desktop-tours");
  }, [navigate]);

  const onTabInformationContainerClick = useCallback(() => {
    navigate("/desktop-information");
  }, [navigate]);

  const onTabAccountContainerClick = useCallback(() => {
    navigate("/desktop-account");
  }, [navigate]);

  return (
    <>
      <nav className={styles.nav} id="nav" onClick={openMobileMenuAccount}>
        <div className={styles.logo}>
          <img className={styles.vectorIcon} alt="" src="/vector10.svg" />
        </div>
        <div className={styles.tabGroup}>
          <div
            className={styles.tabDashboard}
            onClick={onTabDashboardContainerClick}
          >
            <img className={styles.tabIcons} alt="" src="/tab-icons41.svg" />
            <div className={styles.dashboard}>Dashboard</div>
          </div>
          <div
            className={styles.tabDashboard}
            onClick={onTabToursContainerClick}
          >
            <img className={styles.tabIcons} alt="" src="/tab-icons51.svg" />
            <div className={styles.dashboard}>Tours</div>
          </div>
          <div
            className={styles.tabDashboard}
            onClick={onTabInformationContainerClick}
          >
            <img className={styles.tabIcons} alt="" src="/tab-icons2.svg" />
            <div className={styles.dashboard}>Information</div>
          </div>
          <div
            className={styles.tabAccount}
            onClick={onTabAccountContainerClick}
          >
            <img className={styles.tabIcons3} alt="" src="/tab-icons6.svg" />
            <div className={styles.dashboard3}>Account</div>
            <div className={styles.tabAccountChild} />
          </div>
        </div>
        <div className={styles.hamburgerMenu}>
          <img className={styles.groupIcon} alt="" src="/group2.svg" />
        </div>
      </nav>
      {isMobileMenuAccountOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Right"
          onOutsideClick={closeMobileMenuAccount}
        >
          <MobileMenuAccount onClose={closeMobileMenuAccount} />
        </PortalDrawer>
      )}
    </>
  );
};

export default Header;
