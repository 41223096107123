import { FunctionComponent } from "react";
import styles from "./ProfileInfo.module.css";

type ProfileInfoType = {
  overline?: string;
  headline?: string;
};

const ProfileInfo: FunctionComponent<ProfileInfoType> = ({
  overline,
  headline,
}) => {
  return (
    <div className={styles.listItemlistItem0Density}>
      <div className={styles.stateLayerOverlay} />
      <div className={styles.stateLayer}>
        <div className={styles.content}>
          <div className={styles.overline}>{overline}</div>
          <div className={styles.headline}>{headline}</div>
          <div className={styles.supportingText}>
            Supporting line text lorem ipsum dolor sit amet, consectetur.
          </div>
        </div>
        <div className={styles.trailingSupportingText}>100+</div>
      </div>
      <div className={styles.divider}>
        <div className={styles.divider1} />
      </div>
    </div>
  );
};

export default ProfileInfo;
