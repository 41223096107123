import { FunctionComponent } from "react";
import styles from "./CardContainer.module.css";
const CardContainer: FunctionComponent = () => {
  return (
    <div className={styles.stackedCard}>
      <div className={styles.cardStateLayeroutlined}>
        <div className={styles.stateLayer} />
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.mediaTextContent}>
          <img className={styles.mediaIcon} alt="" src="/media@2x.png" />
          <div className={styles.textContent}>
            <div className={styles.headline}>
              <div className={styles.title}>Title</div>
              <div className={styles.subhead}>Subhead</div>
            </div>
            <div className={styles.supportingText}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor
            </div>
            <div className={styles.actions}>
              <div className={styles.secondaryButton}>
                <div className={styles.stateLayer1}>
                  <div className={styles.labelText}>Enabled</div>
                </div>
              </div>
              <div className={styles.primaryButton}>
                <div className={styles.stateLayer1}>
                  <div className={styles.labelText}>Enabled</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardContainer;
