import { FunctionComponent } from "react";
import SignInForm from "../components/SignInForm";
import styles from "./LoggedOut.module.css";
const LoggedOut: FunctionComponent = () => {
  return (
    <div className={styles.loggedOut}>
      <SignInForm />
    </div>
  );
};

export default LoggedOut;
