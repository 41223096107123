import { FunctionComponent } from "react";
import styles from "./Handle.module.css";
const Handle: FunctionComponent = () => {
  return (
    <div className={styles.handle}>
      <div className={styles.handleChild} />
      <div className={styles.handleItem} />
      <div className={styles.handleInner} />
      <div className={styles.ellipseDiv} />
      <div className={styles.handleChild1} />
      <div className={styles.handleChild2} />
    </div>
  );
};

export default Handle;
