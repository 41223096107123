import { FunctionComponent } from "react";
import styles from "./GroupComponent1.module.css";
const GroupComponent1: FunctionComponent = () => {
  return (
    <div className={styles.vectorParent}>
      <img className={styles.vectorIcon} alt="" src="/vector5.svg" />
      <b className={styles.launchintro}>LaunchIntro</b>
    </div>
  );
};

export default GroupComponent1;
