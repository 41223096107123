import { FunctionComponent } from "react";
import styles from "./GroupComponent4.module.css";
const GroupComponent4: FunctionComponent = () => {
  return (
    <div className={styles.vectorParent}>
      <img className={styles.vectorIcon} alt="" src="/vector8.svg" />
      <b className={styles.introkitio}>IntroKit.io</b>
    </div>
  );
};

export default GroupComponent4;
