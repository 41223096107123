import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import DesktopDashboard from "./pages/DesktopDashboard";
import Handle from "./pages/Handle";
import GroupComponent1 from "./pages/GroupComponent1";
import GroupComponent2 from "./pages/GroupComponent2";
import GroupComponent3 from "./pages/GroupComponent3";
import GroupComponent4 from "./pages/GroupComponent4";
import GroupComponent5 from "./pages/GroupComponent5";
import SignUp from "./pages/SignUp";
import LoggedOut from "./pages/LoggedOut";
import DesktopTours from "./pages/DesktopTours";
import DesktopAccount from "./pages/DesktopAccount";
import DesktopInformation from "./pages/DesktopInformation";
import { useEffect, useState } from "react";
import IsTabActive from "./IsTabActive";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const [account, setStateAccount] = useState<any | null>();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const setAccount = (account: any) => {
    var accountCopy = { ...account };
    sessionStorage.setItem("account", JSON.stringify(accountCopy));
    setStateAccount(accountCopy);
  };

  //nuke
  const discardChanges = () => {
    getAccount().then((accountObj) => {
      setAccount(accountObj.account);
    });
  };

  useEffect(() => {
    if (!account) {
      if (!sessionStorage.getItem("account")) {
        getAccount().then((accountObj) => {
          setAccount(accountObj.account);
        });
      } else {
        setAccount(JSON.parse(sessionStorage.getItem("account") ?? ""));
      }
    }

    if (email == "") {
      setEmail(
        getParam(sessionStorage.getItem("introKitToken") ?? "", "email")
      );
    }

    if (name == "") {
      setName(getParam(sessionStorage.getItem("introKitToken") ?? "", "name"));
    }
  }, []);

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/handle":
        title = "";
        metaDescription = "";
        break;
      case "/group-9":
        title = "";
        metaDescription = "";
        break;
      case "/group-7":
        title = "";
        metaDescription = "";
        break;
      case "/group-8":
        title = "";
        metaDescription = "";
        break;
      case "/group-6":
        title = "";
        metaDescription = "";
        break;
      case "/group-5":
        title = "";
        metaDescription = "";
        break;
      case "/sign-up":
        title = "";
        metaDescription = "";
        break;
      case "/logged-out":
        title = "";
        metaDescription = "";
        break;
      case "/desktop-tours":
        title = "";
        metaDescription = "";
        break;
      case "/desktop-account":
        title = "";
        metaDescription = "";
        break;
      case "/desktop-information":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <DesktopDashboard
              account={account}
              setAccount={setAccount}
              email={email}
              name={name}
              discardChanges={discardChanges}
            />
            <IsTabActive />
          </>
        }
      />
      <Route path="/handle" element={<Handle />} />
      <Route path="/group-9" element={<GroupComponent1 />} />
      <Route path="/group-7" element={<GroupComponent2 />} />
      <Route path="/group-8" element={<GroupComponent3 />} />
      <Route path="/group-6" element={<GroupComponent4 />} />
      <Route path="/group-5" element={<GroupComponent5 />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/logged-out" element={<LoggedOut />} />
      <Route path="/desktop-tours" element={<DesktopTours />} />
      <Route path="/desktop-account" element={<DesktopAccount />} />
      <Route path="/desktop-information" element={<DesktopInformation />} />
    </Routes>
  );
}

async function getAccount() {
  const response = await fetch(
    "https://phj1ss4wd8.execute-api.us-west-2.amazonaws.com/default/IntrokitAccountAPI",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        token: sessionStorage.getItem("introKitToken") ?? "",
      },
    }
  );
  return await response.json();
}

function getParam(token: string, param: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload)[param];
}

export default App;
