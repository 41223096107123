import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material";

import "./global.css";

const muiTheme = createTheme();

const container = document.getElementById("root");
const root = createRoot(container!);
const urlParams = new URLSearchParams(window.location.search);
if (!urlParams.get("code") && !sessionStorage.getItem("introKitToken")) {
  //TODO AND NO EXPIRED TOKEN!!
  window.location.replace(
    "http://auth.app.introkit.io/login?response_type=code&client_id=2g31f1rv3aci3m8o6vj68jk4pr&redirect_uri=" +
      getRedirectUri() +
      "&scope=email%20openid+profile"
  );
} else if (urlParams.get("code") && !sessionStorage.getItem("introKitToken")) {
  var code = urlParams.get("code");
  getToken(code).then((token) => {
    sessionStorage.setItem("introKitToken", token.id_token);
    rootRender();
  });
} else {
  rootRender();
}

function rootRender() {
  root.render(
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

async function getToken(code: string | null) {
  var body =
    `grant_type=authorization_code&code=` +
    code +
    `&redirect_uri=` +
    getRedirectUri() +
    `&client_id=2g31f1rv3aci3m8o6vj68jk4pr`;

  const response = await fetch("https://auth.app.introkit.io/oauth2/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: body,
  });
  return await response.json();
}

function getRedirectUri() {
  return document.location.toString().includes("3000")
    ? "http%3A%2F%2Flocalhost:3000"
    : "https%3A%2F%2Fapp.introkit.io";
}
