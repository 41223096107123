import { FunctionComponent, useState, useMemo, useCallback } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import DesktopToursDetailView from "./DesktopToursDetailView";
import PortalPopup from "./PortalPopup";
import CSS, { Property } from "csstype";
import styles from "./TourContainer.module.css";

type TourContainerType = {
  stepCount?: string;
  title?: string;
  description?: string;
  started?: string;
  completionRate?: string;
  embedCode?: string;

  /** Style props */
  propAlignSelf?: Property.AlignSelf;
  propDisplay?: Property.Display;
  propAlignItems?: Property.AlignItems;
  propWidth?: Property.Width;
  propCursor?: Property.Cursor;
};

const TourContainer: FunctionComponent<TourContainerType> = ({
  stepCount,
  title,
  description,
  started,
  completionRate,
  embedCode,
  propAlignSelf,
  propDisplay,
  propAlignItems,
  propWidth,
  propCursor,
}) => {
  const [isDesktopToursDetailViewOpen, setDesktopToursDetailViewOpen] =
    useState(false);
  const headlineStyle: CSS.Properties = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
      display: propDisplay,
      alignItems: propAlignItems,
      width: propWidth,
    };
  }, [propAlignSelf, propDisplay, propAlignItems, propWidth]);

  const buttonStyle: CSS.Properties = useMemo(() => {
    return {
      cursor: propCursor,
    };
  }, [propCursor]);

  const openDesktopToursDetailView = useCallback(() => {
    setDesktopToursDetailViewOpen(true);
  }, []);

  const closeDesktopToursDetailView = useCallback(() => {
    setDesktopToursDetailViewOpen(false);
  }, []);

  return (
    <>
      <div className={styles.tourList}>
        <FormControlLabel
          className={styles.switch}
          label=""
          control={<Switch color="primary" size="medium" />}
        />
        <div className={styles.title}>
          <div className={styles.overline}>
            <div className={styles.overline1}>{stepCount}</div>
            <div className={styles.overline1}>STEPS</div>
          </div>
          <b className={styles.headline} style={headlineStyle}>
            {title}
          </b>
          <div className={styles.supportingText}>{description}</div>
        </div>
        <div className={styles.started}>
          <div className={styles.overline3}>Started</div>
          <div className={styles.headline}>{started}</div>
        </div>
        <div className={styles.completed}>
          <div className={styles.overline3}>Completion Rate</div>
          <div className={styles.headlineParent}>
            <div className={styles.headline2}>{completionRate}</div>
            <div className={styles.headline3}>%</div>
          </div>
        </div>
        <div className={styles.embedcode}>
          <div className={styles.overline3}>Embed Code</div>
          <div className={styles.headline4}>{embedCode}</div>
        </div>
        <div
          className={styles.button}
          onClick={openDesktopToursDetailView}
          style={buttonStyle}
        >
          <div className={styles.stateLayer}>
            <div className={styles.labelText}>Details</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TourContainer;
