import { FunctionComponent, useState, useCallback } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MobileMenuTours from "../components/MobileMenuTours";
import PortalDrawer from "../components/PortalDrawer";
import TourContainer from "../components/TourContainer";
import styles from "./DesktopTours.module.css";
const DesktopTours: FunctionComponent = () => {
  const navigate = useNavigate();
  const [isMobileMenuToursOpen, setMobileMenuToursOpen] = useState(false);

  const onTabDashboardContainerClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onTabToursContainerClick = useCallback(() => {
    navigate("/desktop-tours");
  }, [navigate]);

  const onTabInformationContainerClick = useCallback(() => {
    navigate("/desktop-information");
  }, [navigate]);

  const onTabAccountContainerClick = useCallback(() => {
    navigate("/desktop-account");
  }, [navigate]);

  const openMobileMenuTours = useCallback(() => {
    setMobileMenuToursOpen(true);
  }, []);

  const closeMobileMenuTours = useCallback(() => {
    setMobileMenuToursOpen(false);
  }, []);

  return (
    <>
      <div className={styles.desktopTours}>
        <nav className={styles.nav} id="nav">
          <div className={styles.logo}>
            <img className={styles.vectorIcon} alt="" src="/vector10.svg" />
          </div>
          <div className={styles.tabGroup}>
            <div
              className={styles.tabDashboard}
              onClick={onTabDashboardContainerClick}
            >
              <img className={styles.tabIcons} alt="" src="/tab-icons13.svg" />
              <div className={styles.dashboard}>Dashboard</div>
            </div>
            <div className={styles.tabTours} onClick={onTabToursContainerClick}>
              <img
                className={styles.tabIcons1}
                alt=""
                src="/tab-icons131.svg"
              />
              <div className={styles.dashboard1}>Tours</div>
              <div className={styles.tabToursChild} />
            </div>
            <div
              className={styles.tabDashboard}
              onClick={onTabInformationContainerClick}
            >
              <img className={styles.tabIcons} alt="" src="/tab-icons2.svg" />
              <div className={styles.dashboard}>Information</div>
            </div>
            <div
              className={styles.tabDashboard}
              onClick={onTabAccountContainerClick}
            >
              <img className={styles.tabIcons} alt="" src="/tab-icons31.svg" />
              <div className={styles.dashboard}>Account</div>
            </div>
          </div>
          <div className={styles.hamburgerMenu} onClick={openMobileMenuTours}>
            <img className={styles.groupIcon} alt="" src="/group11.svg" />
          </div>
        </nav>
        <div className={styles.appWindow}>
          <div className={styles.toursParent}>
            <div className={styles.tours}>Tours</div>
            <div className={styles.tours}>/</div>
          </div>
          <div className={styles.header}>
            <b className={styles.allTours}>All Tours</b>
            <button className={styles.button}>
              <div className={styles.stateLayer}>
                <img className={styles.icon} alt="" src="/icon.svg" />
                <div className={styles.labelText}>New Tour</div>
              </div>
            </button>
          </div>
          <div className={styles.content}>
            <TourContainer
              stepCount="4"
              title="Marketing Site Tour"
              description="Walkthrough of how to add products to your cart on apple.com"
              started="54"
              completionRate="23"
              embedCode={`<launchintro id="1k2k2k24">`}
              propAlignSelf="stretch"
              propDisplay="inline-block"
              propCursor="pointer"
            />
            <TourContainer
              stepCount="6"
              title="Wiki Walkthrough"
              description="Description of our wiki page"
              started="121"
              completionRate="75"
              embedCode={`<launchintro id="23k29d92">`}
              propAlignSelf="unset"
              propDisplay="flex"
              propAlignItems="center"
              propWidth="615px"
              propCursor="unset"
            />
            <div className={styles.tourList}>
              <FormControlLabel
                className={styles.allTours}
                label=""
                control={<Switch color="primary" size="medium" />}
              />
              <div className={styles.title}>
                <div className={styles.overline}>
                  <div className={styles.overline1}>4</div>
                  <div className={styles.overline1}>STEPS</div>
                </div>
                <b className={styles.headline}>How to Change Product Values</b>
                <div className={styles.supportingText}>
                  Walkthrough of how to add products to your cart on apple.com
                </div>
              </div>
              <div className={styles.started}>
                <div className={styles.overline3}>Started</div>
                <div className={styles.headline1}>54</div>
              </div>
              <div className={styles.completed}>
                <div className={styles.overline3}>Completion Rate</div>
                <div className={styles.headlineParent}>
                  <div className={styles.headline2}>23</div>
                  <div className={styles.headline3}>%</div>
                </div>
              </div>
              <div className={styles.embedcode}>
                <div className={styles.overline3}>Embed Code</div>
                <div
                  className={styles.headline4}
                >{`<launchintro id="1k2k2k24">`}</div>
              </div>
              <div className={styles.button1}>
                <div className={styles.stateLayer1}>
                  <div className={styles.dashboard}>Details</div>
                </div>
              </div>
            </div>
            <div className={styles.tourList}>
              <FormControlLabel
                className={styles.allTours}
                label=""
                control={<Switch color="primary" size="medium" />}
              />
              <div className={styles.title}>
                <div className={styles.overline}>
                  <div className={styles.overline1}>6</div>
                  <div className={styles.overline1}>STEPS</div>
                </div>
                <b className={styles.headline}>Building a new Tour</b>
                <div className={styles.supportingText}>
                  How to build a tour on the launchIntro interface
                </div>
              </div>
              <div className={styles.started}>
                <div className={styles.overline3}>Started</div>
                <div className={styles.headline1}>8</div>
              </div>
              <div className={styles.completed}>
                <div className={styles.overline3}>Completion Rate</div>
                <div className={styles.headlineParent}>
                  <div className={styles.headline2}>14</div>
                  <div className={styles.headline3}>%</div>
                </div>
              </div>
              <div className={styles.embedcode}>
                <div className={styles.overline3}>Embed Code</div>
                <div
                  className={styles.headline4}
                >{`<launchintro id="1k2k2k24">`}</div>
              </div>
              <div className={styles.button1}>
                <div className={styles.stateLayer1}>
                  <div className={styles.dashboard}>Details</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isMobileMenuToursOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Right"
          onOutsideClick={closeMobileMenuTours}
        >
          <MobileMenuTours onClose={closeMobileMenuTours} />
        </PortalDrawer>
      )}
    </>
  );
};

export default DesktopTours;
