import { FunctionComponent } from "react";
import SignUpForm from "../components/SignUpForm";
import styles from "./SignUp.module.css";
const SignUp: FunctionComponent = () => {
  return (
    <div className={styles.signUp}>
      <SignUpForm />
    </div>
  );
};

export default SignUp;
