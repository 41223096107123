import { FunctionComponent, useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  TextField,
  FormControlLabel,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icon,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select,
  Alert,
} from "@mui/material";
import styles from "./DesktopToursDetailView.module.css";
var cloneDeep = require("lodash.clonedeep");

type DesktopClicksToursDetailViewType = {
  account: any;
  setTourStep: any;
  tourIndex: any;
  tourStepIndex: number;
  clicks: any;
};

const DesktopClicksToursDetailView: FunctionComponent<
  DesktopClicksToursDetailViewType
> = ({ account, setTourStep, tourIndex, tourStepIndex, clicks }) => {
  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    account.tours[tourIndex].tourSteps[tourStepIndex].clicks = reorder(
      account.tours[tourIndex].tourSteps[tourStepIndex].clicks,
      result.source.index,
      result.destination.index
    );

    setTourStep(
      tourIndex,
      "clicks",
      account.tours[tourIndex].tourSteps[tourStepIndex].clicks
    );
  };

  const setClickStep = (clickIndex: number, prop: string, value: any) => {
    account.tours[tourIndex ?? 0].tourSteps[tourStepIndex].clicks[clickIndex][
      prop
    ] = value;
    setTourStep(
      tourStepIndex,
      "clicks",
      account.tours[tourIndex ?? 0].tourSteps[tourStepIndex].clicks
    );
  };

  const deleteClickStep = (clickIndex: number) => {
    account.tours[tourIndex].tourSteps[tourStepIndex].clicks.splice(
      clickIndex ?? 0,
      1
    );
    setTourStep(
      tourStepIndex,
      "clicks",
      account.tours[tourIndex ?? 0].tourSteps[tourStepIndex].clicks
    );
  };

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const grid = 8;
  const getItemStyle = (isDragging: Boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: "2px 0",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    background: "#fef7ff",
    width: "100%",
    padding: 0,
  });

  const addClick = () => {
    account.tours[tourIndex ?? 0].tourSteps[tourStepIndex].clicks.push({
      selectionType: "",
      querySelectorType: "",
      querySelectorInnerText: "",
      id: "",
      cssClass: "",
      clickId: new Date().getTime(),
    });
    setTourStep(
      tourStepIndex,
      "clicks",
      account.tours[tourIndex ?? 0].tourSteps[tourStepIndex].clicks
    );
  };

  return (
    <div className={styles.content2}>
      <div className={styles.helperText}>
        Use this section to add a series of simulated clicks to display the
        target you want to highlight. This way you can open menus to highlight
        items that aren't visible by default on your page.
      </div>
      <hr />
      <div className={styles.labelTextButton} onClick={addClick}>
        + Add a Click
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {clicks.map((click: any, index: number) => {
                return (
                  <Draggable
                    draggableId={click.clickId.toString()}
                    key={click.clickId}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <Accordion
                          key={click.clickId}
                          className={styles.accordion}
                        >
                          <AccordionSummary
                            expandIcon={<Icon>expand_more</Icon>}
                          >
                            <Typography
                              component={"span"}
                              sx={{ width: "80%", flexShrink: 0 }}
                            >
                              {" "}
                              <div className={styles.container}>
                                <img
                                  className={styles.handleIcon}
                                  alt=""
                                  src="/handle.svg"
                                  {...provided.dragHandleProps}
                                />
                                <div className={styles.body1}>
                                  {"Click " + (index + 1)}
                                </div>
                              </div>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography component={"span"}>
                              <div className={styles.content2}>
                                <FormControl
                                  className={styles.select}
                                  variant="outlined"
                                >
                                  <InputLabel color="primary">
                                    Selection Method
                                  </InputLabel>
                                  <Select
                                    color="primary"
                                    name="Selection Method"
                                    size="medium"
                                    label="Selection Method"
                                    onChange={(e) => {
                                      setClickStep(
                                        index,
                                        "selectionType",
                                        e.target.value
                                      );
                                    }}
                                    value={click.selectionType}
                                  >
                                    <MenuItem value="Select By Element ID">
                                      Select By Element ID
                                    </MenuItem>
                                    <MenuItem value="Select By CSS Class">
                                      Select By CSS Class
                                    </MenuItem>
                                    <MenuItem value="Select by HTML Content">
                                      Select by HTML Content
                                    </MenuItem>
                                  </Select>
                                  <FormHelperText>
                                    Choose the way you would like to target your
                                    click for this step.
                                  </FormHelperText>
                                </FormControl>
                                {click.selectionType ===
                                  "Select By Element ID" && (
                                  <TextField
                                    className={styles.textfield2}
                                    color="primary"
                                    variant="outlined"
                                    type="text"
                                    label="Target ID"
                                    //placeholder="Placeholder"
                                    size="medium"
                                    margin="none"
                                    onChange={(e) => {
                                      setClickStep(index, "id", e.target.value);
                                    }}
                                    value={click.id}
                                    error={
                                      click.selectionType ===
                                        "Select By Element ID" &&
                                      click.id === ""
                                    }
                                    // prettier-ignore
                                    helperText={
                                      (click.selectionType ===
                                        "Select By Element ID" &&
                                      click.id === "")
                                        ? "Target Inner Html is required"
                                        : "Enter the ID of the element you want to click. You can add an ID to the element if it doesn't have one, or choose a different way to target. Remember, elements can have only one ID and each ID can only be used once on each page."
                                    }
                                  />
                                )}
                                {click.selectionType ===
                                  "Select By CSS Class" && (
                                  <TextField
                                    className={styles.textfield2}
                                    color="primary"
                                    variant="outlined"
                                    type="text"
                                    label="CSS Class"
                                    //placeholder="Placeholder"
                                    size="medium"
                                    margin="none"
                                    onChange={(e) => {
                                      setClickStep(
                                        index,
                                        "cssClass",
                                        e.target.value
                                      );
                                    }}
                                    value={click.cssClass}
                                    error={
                                      click.selectionType ===
                                        "Select By CSS Class" &&
                                      click.cssClass === ""
                                    }
                                    // prettier-ignore
                                    helperText={
                                      (click.selectionType ===
                                        "Select By CSS Class" &&
                                     click.cssClass === "")
                                        ? "Target Inner Html is required"
                                        : "Enter a css class name assigned to the element you want to click. HTML elements can have multiple classes and classes can also be applied to multiple objects. Because the first element with your class name will be targeted we recommend creating and adding a unique class name for each step."
                                    }
                                  />
                                )}
                                {click.selectionType ===
                                  "Select by HTML Content" && (
                                  <>
                                    <hr />
                                    <TextField
                                      className={styles.textfield2}
                                      color="primary"
                                      variant="outlined"
                                      type="text"
                                      label="Target Parent Tag Type"
                                      //placeholder="Placeholder"
                                      size="medium"
                                      margin="none"
                                      onChange={(e) => {
                                        setClickStep(
                                          index,
                                          "querySelectorType",
                                          e.target.value
                                        );
                                      }}
                                      value={click.querySelectorType}
                                      error={
                                        click.selectionType ===
                                          "Select by HTML Content" &&
                                        click.querySelectorType === ""
                                      }
                                      // prettier-ignore
                                      helperText={
                                        (click.selectionType ===
                                          "Select by HTML Content" &&
                                          click.querySelectorType === "")
                                          ? "Target Inner Html is required"
                                          : "Enter the tag type of the parent element of your desired click target."
                                      }
                                    />
                                    <TextField
                                      className={styles.textfield2}
                                      color="primary"
                                      variant="outlined"
                                      type="text"
                                      label="Target Inner Html"
                                      //placeholder="Placeholder"
                                      size="medium"
                                      margin="none"
                                      onChange={(e) => {
                                        setClickStep(
                                          index,
                                          "querySelectorInnerText",
                                          e.target.value
                                        );
                                      }}
                                      value={click.querySelectorInnerText}
                                      error={
                                        click.selectionType ===
                                          "Select by HTML Content" &&
                                        click.querySelectorInnerText === ""
                                      }
                                      // prettier-ignore
                                      helperText={
                                        (click.selectionType ===
                                          "Select by HTML Content" &&
                                        click.querySelectorInnerText ===
                                          "")
                                          ? "Target Inner Html is required"
                                          : "Enter some unique text or html contained by target element. The first instance of an element containing this text or html (with a parent matching the parent tag type) will be clicked."
                                      }
                                    />
                                    <hr />
                                    <div className={styles.helperText}>
                                      <b>Example</b>
                                      <br />
                                      {`Given: <li><a>About My Site</a></li>`}
                                      <br />
                                      {`To click the a tag, the parent tag type would be "li" and the inner html could be "About My Site".`}
                                    </div>
                                  </>
                                )}
                                <hr />
                                <button
                                  className={styles.trashDeleteBin1Parent}
                                  onClick={() => deleteClickStep(index)}
                                >
                                  <img
                                    className={styles.handleIcon}
                                    alt=""
                                    src="/trash-delete-bin-1.svg"
                                  />
                                  <b className={styles.labelText7}>
                                    DELETE Click
                                  </b>
                                </button>
                              </div>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default DesktopClicksToursDetailView;
