import { FunctionComponent, useState, useCallback, useEffect } from "react";
import DesktopToursDetailView from "../components/DesktopToursDetailView";
import PortalPopup from "../components/PortalPopup";
import { useNavigate } from "react-router-dom";
import TourListItem from "../components/TourListItem";
import styles from "./DesktopDashboard.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import { ThemeProvider, createTheme } from "@mui/material/styles";
var cloneDeep = require("lodash.clonedeep");
type Props = {
  account: any;
  setAccount: any;
  email: string;
  name: string;
  discardChanges: any;
};
const DesktopDashboard: FunctionComponent<Props> = (props: Props) => {
  const [isDesktopToursDetailViewOpen, setDesktopToursDetailViewOpen] =
    useState(false);
  const navigate = useNavigate();
  const onButtonClick = useCallback(() => {
    sessionStorage.clear();
    window.location.replace("/");
  }, [navigate]);
  const [loading, setLoading] = useState(false);
  const openDesktopToursDetailView = useCallback(() => {
    setDesktopToursDetailViewOpen(true);
  }, []);

  const closeDesktopToursDetailView = useCallback(() => {
    setDesktopToursDetailViewOpen(false);
  }, []);

  const onButton2Click = useCallback(() => {
    sessionStorage.clear();
    window.location.replace("/");
  }, [navigate]);

  const onButton3Click = useCallback(
    (accountId: any) => {
      sessionStorage.clear();
      window.location.replace(
        "https://buy.stripe.com/aEU14U3aM2z1eL68ww?client_reference_id=" +
          accountId
      );
    },
    [navigate]
  );

  const onButton4Click = useCallback(() => {
    sessionStorage.clear();
    window.location.replace(
      "https://billing.stripe.com/p/login/7sIeVq7Rf6j45xe9AA"
    );
  }, [navigate]);

  const onAddTour = () => {
    setLoading(true);
    var copy = cloneDeep(props.account);
    copy.tours.push({
      tourId: new Date().getTime() / 1000,
      tourTitle: "New Tour " + (props.account.tours?.length + 1),
      showButton: true,
      mode: "desktop",
      enabled: true,
      tourSteps: [],
      primaryColor: "",
    });
    saveAccount(copy).then((result: any) => {
      if (result.account === "updated") {
        props.setAccount(copy);
      }
      setLoading(false);
    });
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#ffffff",
      },
    },
  });

  async function saveAccount(account: any) {
    const response = await fetch(
      "https://phj1ss4wd8.execute-api.us-west-2.amazonaws.com/default/IntrokitAccountAPI",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          token: sessionStorage.getItem("introKitToken") ?? "",
        },
        body: `{   "account": ` + JSON.stringify(account) + `}`,
      }
    );
    return await response.json();
  }

  return (
    <>
      <div className={styles.desktopDashboard}>
        <nav className={styles.nav} id="nav">
          <b className={styles.introkitio}>
            <img alt="" src="/introKit_logo_black.svg" />
          </b>
          <button className={styles.button} onClick={onButtonClick}>
            <div className={styles.stateLayer}>
              <img
                className={styles.handSelect21}
                alt=""
                src="/hand-select2-1.svg"
              />
              <div className={styles.labelText}>Sign Out</div>
            </div>
          </button>
        </nav>
        <div className={styles.appWindow}>
          <div className={styles.welcome}>
            <div className={styles.header}>
              <b className={styles.introkitio}>Welcome back {props.name}</b>
            </div>
            <div className={styles.content} />
          </div>
          <div className={styles.welcome}>
            <div className={styles.header1}>
              <b className={styles.introkitio} data-scroll-to="myToursText">
                My Tours
              </b>
              <button
                className={styles.button1}
                onClick={onAddTour}
                disabled={props.account === undefined || loading}
                hidden={props.account === undefined}
              >
                <div className={styles.stateLayer}>
                  {!loading ? (
                    <img className={styles.icon} alt="" src="/icon.svg" />
                  ) : (
                    <div className={styles.icon}>
                      <ThemeProvider theme={theme}>
                        <CircularProgress size="1rem" color="primary" />
                      </ThemeProvider>
                    </div>
                  )}
                  <div className={styles.labelText}>New Tour</div>{" "}
                </div>
              </button>
            </div>
            <div className={styles.toursList}>
              {props.account &&
                props.account.tours &&
                props.account.tours.map((tours: any, index: number) => {
                  return (
                    <TourListItem
                      account={props.account}
                      setAccount={props.setAccount}
                      tourIndex={index}
                      key={tours.tourId}
                      discardChanges={props.discardChanges}
                      saveAccount={saveAccount}
                    />
                  );
                })}
            </div>
          </div>
          <div className={styles.wiki}>
            <div className={styles.toursList1}>
              <div className={styles.header2}>
                <b
                  className={styles.introkitio}
                  data-scroll-to="informationText"
                >
                  Information
                </b>
              </div>
              <div className={styles.contentWrapper}>
                <div className={styles.content1}>
                  <div className={styles.stackedCard}>
                    <div className={styles.cardStateLayeroutlined}>
                      <div className={styles.stateLayer5} />
                    </div>
                    <div className={styles.contentContainer}>
                      <div className={styles.mediaTextContent}>
                        <img
                          className={styles.mediaIcon}
                          alt=""
                          src="/media@2x.png"
                        />
                        <div className={styles.textContent}>
                          <div className={styles.headline13}>
                            <div className={styles.title4}>Title</div>
                            <div className={styles.subhead}>Subhead</div>
                          </div>
                          <div className={styles.subhead}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor
                          </div>
                          <button className={styles.actions}>
                            <div className={styles.secondaryButton}>
                              <div className={styles.stateLayer6}>
                                <div className={styles.labelText5}>Enabled</div>
                              </div>
                            </div>
                            <div className={styles.primaryButton}>
                              <div className={styles.stateLayer6}>
                                <div className={styles.labelText}>Enabled</div>
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.stackedCard1}>
                    <div className={styles.cardStateLayeroutlined}>
                      <div className={styles.stateLayer5} />
                    </div>
                    <div className={styles.contentContainer}>
                      <div className={styles.mediaTextContent}>
                        <img
                          className={styles.mediaIcon}
                          alt=""
                          src="/media@2x.png"
                        />
                        <div className={styles.textContent}>
                          <div className={styles.headline14}>
                            <div className={styles.title4}>Title</div>
                            <div className={styles.subhead}>Subhead</div>
                          </div>
                          <div className={styles.supportingText1}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor
                          </div>
                          <div className={styles.actions1}>
                            <div className={styles.secondaryButton}>
                              <div className={styles.stateLayer6}>
                                <div className={styles.labelText2}>Enabled</div>
                              </div>
                            </div>
                            <div className={styles.primaryButton1}>
                              <div className={styles.stateLayer6}>
                                <div className={styles.labelText2}>Enabled</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.stackedCard}>
                    <div className={styles.cardStateLayeroutlined}>
                      <div className={styles.stateLayer5} />
                    </div>
                    <div className={styles.contentContainer}>
                      <div className={styles.mediaTextContent}>
                        <img
                          className={styles.mediaIcon}
                          alt=""
                          src="/media@2x.png"
                        />
                        <div className={styles.textContent}>
                          <div className={styles.headline13}>
                            <div className={styles.title4}>Title</div>
                            <div className={styles.subhead}>Subhead</div>
                          </div>
                          <div className={styles.subhead}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor
                          </div>
                          <button className={styles.actions}>
                            <div className={styles.secondaryButton}>
                              <div className={styles.stateLayer6}>
                                <div className={styles.labelText5}>Enabled</div>
                              </div>
                            </div>
                            <div className={styles.primaryButton}>
                              <div className={styles.stateLayer6}>
                                <div className={styles.labelText}>Enabled</div>
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.myProfile}>
            <div className={styles.header3}>
              <b className={styles.introkitio} data-scroll-to="accountText">
                Account
              </b>
              <button className={styles.button1} onClick={onButton2Click}>
                <div className={styles.stateLayer}>
                  <img
                    className={styles.handSelect21}
                    alt=""
                    src="/hand-select2-1.svg"
                  />
                  <div className={styles.labelText}>Sign Out</div>
                </div>
              </button>
            </div>
            <div className={styles.content2}>
              <div className={styles.listItemlistItem0DensityParent}>
                <div className={styles.listItemlistItem0Density}>
                  <div className={styles.stateLayerOverlay} />
                  <div className={styles.stateLayer16}>
                    <div className={styles.content3}>
                      <div className={styles.overline1}>Name</div>
                      <div className={styles.headline16}>{props.name}</div>
                    </div>
                  </div>
                  <div className={styles.divider}>
                    <div className={styles.divider1} />
                  </div>
                </div>
                <div className={styles.listItemlistItem0Density}>
                  <div className={styles.stateLayerOverlay} />
                  <div className={styles.stateLayer16}>
                    <div className={styles.content3}>
                      <div className={styles.overline1}>Email Address</div>
                      <div className={styles.headline16}>{props.email}</div>
                    </div>
                  </div>
                  <div className={styles.divider}>
                    <div className={styles.divider1} />
                  </div>
                </div>
                {props.account && props.account.paid && (
                  <div className={styles.listItemlistItem0Density}>
                    <div className={styles.stateLayerOverlay} />
                    <div className={styles.stateLayer18}>
                      <div className={styles.content3}>
                        <div className={styles.overline1}>Payment Plan</div>
                        <div className={styles.headline16}>
                          Premium, Monthly
                        </div>
                        <div className={styles.description}>
                          Supporting line text lorem ipsum dolor sit amet,
                          consectetur.
                        </div>
                      </div>
                      <div className={styles.trailingSupportingText}>100+</div>
                    </div>
                    <div className={styles.divider}>
                      <div className={styles.divider1} />
                    </div>
                  </div>
                )}
                {props.account && props.account.paid && (
                  <button className={styles.button6} onClick={onButton4Click}>
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.handSelect21}
                        alt=""
                        src="/credit-card-lock-1.svg"
                      />
                      <div className={styles.labelText}>
                        Manage Subscription
                      </div>
                    </div>
                  </button>
                )}
                {props.account && !props.account.paid && (
                  <button
                    className={styles.button6}
                    onClick={() => onButton3Click(props.account.accountId)}
                  >
                    <div className={styles.stateLayer}>
                      <img
                        className={styles.handSelect21}
                        alt=""
                        src="/credit-card-lock-1.svg"
                      />
                      <div className={styles.labelText}>Setup Subscription</div>
                    </div>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DesktopDashboard;
