import { FunctionComponent } from "react";
import styles from "./GroupComponent2.module.css";
const GroupComponent2: FunctionComponent = () => {
  return (
    <div className={styles.vectorParent}>
      <img className={styles.vectorIcon} alt="" src="/vector6.svg" />
      <b className={styles.launchintro}>LaunchIntro</b>
    </div>
  );
};

export default GroupComponent2;
