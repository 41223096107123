import { FunctionComponent, useCallback } from "react";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import ProfileInfo from "../components/ProfileInfo";
import styles from "./DesktopAccount.module.css";
const DesktopAccount: FunctionComponent = () => {
  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    navigate("/logged-out");
  }, [navigate]);

  const onButton1Click = useCallback(() => {
    navigate("/logged-out");
  }, [navigate]);

  return (
    <div className={styles.desktopAccount}>
      <Header />
      <div className={styles.appWindow}>
        <div className={styles.accountParent}>
          <div className={styles.account}>ACCOUNT</div>
          <div className={styles.account}>/</div>
        </div>
        <div className={styles.header}>
          <b className={styles.yourProfile}>Your Profile</b>
          <button className={styles.button} onClick={onButtonClick}>
            <div className={styles.stateLayer}>
              <img
                className={styles.handSelect21}
                alt=""
                src="/hand-select2-11.svg"
              />
              <div className={styles.labelText}>Sign Out</div>
            </div>
          </button>
        </div>
        <div className={styles.content}>
          <ProfileInfo overline="Your Name" headline="Alex Sams" />
          <div className={styles.listItemlistItem0Density}>
            <div className={styles.stateLayerOverlay} />
            <div className={styles.stateLayer1}>
              <div className={styles.content1}>
                <div className={styles.overline}>Email Address</div>
                <div className={styles.headline}>alex.sams@gmail.com</div>
                <div className={styles.supportingText}>
                  Supporting line text lorem ipsum dolor sit amet, consectetur.
                </div>
              </div>
              <div className={styles.trailingSupportingText}>100+</div>
            </div>
            <div className={styles.divider}>
              <div className={styles.divider1} />
            </div>
          </div>
          <div className={styles.listItemlistItem0Density1}>
            <div className={styles.stateLayerOverlay} />
            <div className={styles.stateLayer2}>
              <div className={styles.content2}>
                <div className={styles.overline}>Street Address</div>
                <div className={styles.headline}>
                  <p className={styles.mainStreet}>123 Main Street</p>
                  <p className={styles.mainStreet}>City, State, 1234567</p>
                </div>
                <div className={styles.supportingText}>
                  Supporting line text lorem ipsum dolor sit amet, consectetur.
                </div>
              </div>
              <div className={styles.trailingSupportingText}>100+</div>
              <div className={styles.minHeightFix} />
            </div>
            <div className={styles.divider}>
              <div className={styles.divider1} />
            </div>
          </div>
          <div className={styles.listItemlistItem0Density}>
            <div className={styles.stateLayerOverlay} />
            <div className={styles.stateLayer3}>
              <div className={styles.content1}>
                <div className={styles.overline}>Payment Plan</div>
                <div className={styles.headline}>Premium, Monthly</div>
                <div className={styles.supportingText}>
                  Supporting line text lorem ipsum dolor sit amet, consectetur.
                </div>
              </div>
              <div className={styles.trailingSupportingText}>100+</div>
            </div>
            <div className={styles.divider}>
              <div className={styles.divider1} />
            </div>
          </div>
          <button className={styles.button1} onClick={onButton1Click}>
            <div className={styles.stateLayer}>
              <img
                className={styles.handSelect21}
                alt=""
                src="/credit-card-lock-1.svg"
              />
              <div className={styles.labelText}>Manage Subscription</div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DesktopAccount;
