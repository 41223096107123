import { FunctionComponent, useCallback } from "react";
import { TextField, FormControlLabel, Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./SignUpForm.module.css";
const SignUpForm: FunctionComponent = () => {
  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className={styles.inputs}>
      <div className={styles.title}>
        <div className={styles.letsGetStarted}>Let’s get started!</div>
        <div className={styles.justAFew}>
          Just a few more details and we will get you launched
        </div>
      </div>
      <TextField
        className={styles.userId}
        sx={{ width: 350 }}
        color="primary"
        variant="filled"
        type="text"
        label="Your Name"
        placeholder="Placeholder"
        size="medium"
        margin="none"
      />
      <TextField
        className={styles.userId}
        sx={{ width: 350 }}
        color="primary"
        variant="filled"
        type="text"
        label="Email Address"
        placeholder="Placeholder"
        size="medium"
        margin="none"
      />
      <TextField
        className={styles.userId}
        sx={{ width: 350 }}
        color="primary"
        variant="filled"
        defaultValue="Reset my password"
        type="text"
        label="Password"
        placeholder="Placeholder"
        size="medium"
        margin="none"
      />
      <div className={styles.rememberMe}>
        <FormControlLabel
          className={styles.switch}
          label=""
          control={<Switch color="primary" size="medium" />}
        />
        <div className={styles.rememberMe1}>Remember me</div>
      </div>
      <button className={styles.button} onClick={onButtonClick}>
        <div className={styles.stateLayer}>
          <img
            className={styles.promotionRocket1}
            alt=""
            src="/promotion-rocket-1.svg"
          />
          <div className={styles.labelText}>Sign In</div>
        </div>
      </button>
      <div className={styles.alreadyHaveAn}>Already have an account?</div>
      <button className={styles.button1}>
        <div className={styles.stateLayer1}>
          <div className={styles.labelText1}>Sign In</div>
        </div>
      </button>
    </div>
  );
};

export default SignUpForm;
