import { FunctionComponent, useState, useCallback } from "react";
import DesktopToursDetailView from "./DesktopToursDetailView";
import PortalPopup from "./PortalPopup";
import styles from "./Tour-list-item.module.css";
import { FormControlLabel, Switch } from "@mui/material";
var cloneDeep = require("lodash.clonedeep");
type TourListItemType = {
  account?: any;
  setAccount?: any;
  tourIndex?: number;
  discardChanges: any;
  saveAccount: any;
};

const TourListItem: FunctionComponent<TourListItemType> = ({
  account,
  setAccount,
  tourIndex,
  discardChanges,
  saveAccount,
}) => {
  const [isDesktopToursDetailViewOpen, setDesktopToursDetailViewOpen] =
    useState(false);

  const openDesktopToursDetailView = useCallback(() => {
    setDesktopToursDetailViewOpen(true);
  }, []);

  const closeDesktopToursDetailView = useCallback(() => {
    setDesktopToursDetailViewOpen(false);
  }, []);

  const [disabled, setDisabled] = useState(false);

  const enabled = () => {
    setDisabled(true);
    var clone = cloneDeep(account);
    clone.tours[tourIndex ?? 0].enabled =
      !account.tours[tourIndex ?? 0].enabled;
    saveAccount(clone).then((result: any) => {
      if (result.account === "updated") {
        setDisabled(false);
        setAccount(clone);
      }
    });
  };

  return (
    <>
      <div className={styles.tourListItem}>
        <FormControlLabel
          label=""
          control={
            <Switch
              color="primary"
              size="medium"
              onChange={(e) => enabled()}
              checked={account.tours[tourIndex ?? 0].enabled}
              disabled={disabled}
            />
          }
        />
        <div className={styles.title}>
          <div className={styles.overline}>
            <div className={styles.numberOfSteps}>
              {account.tours[tourIndex ?? 0].tourSteps.length}
            </div>
            <div className={styles.numberOfSteps}>STEPS</div>
          </div>
          <b className={styles.headline}>
            {account.tours[tourIndex ?? 0].tourTitle}
          </b>
          <div className={styles.description}>
            Here's a walkthrough of how you can easily create tours for your
            site
          </div>
        </div>
        <div className={styles.analytics}>
          <div className={styles.title}>
            <div className={styles.overline1}>Started</div>
            <div className={styles.headline}>8</div>
          </div>
          <div className={styles.title}>
            <div className={styles.overline1}>Completion Rate</div>
            <div className={styles.headlineParent}>
              <div className={styles.headline1}>14</div>
              <div className={styles.headline2}>%</div>
            </div>
          </div>
        </div>
        <div className={styles.embedcode}>
          <div className={styles.overline1}>Embed Code</div>
          <div className={styles.embedcodeval}>
            {`  <script
    src="https://app.introkit.io/Tour.js"
    accountID="` +
              account.accountId +
              `"
    tourID="` +
              account.tours[tourIndex ?? 0].tourId +
              `"
  ></script>`}
          </div>
        </div>
        <div className={styles.button} onClick={openDesktopToursDetailView}>
          <div className={styles.stateLayer}>
            <div className={styles.labelText}>Details</div>
          </div>
        </div>
      </div>
      {isDesktopToursDetailViewOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          //onOutsideClick={closeDesktopToursDetailView}
        >
          <DesktopToursDetailView
            account={account}
            setAccount={setAccount}
            tourIndex={tourIndex}
            script={
              `  <script
            src="https://app.introkit.io/Tour.js"
            accountID="` +
              account.accountId +
              `"
            tourID="` +
              account.tours[tourIndex ?? 0].tourId +
              `"
          ></script>`
            }
            discardChanges={discardChanges}
            onClose={closeDesktopToursDetailView}
            saveAccount={saveAccount}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default TourListItem;
