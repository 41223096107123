import { FunctionComponent, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./MobileMenuInformation.module.css";

type MobileMenuInformationType = {
  onClose?: () => void;
};

const MobileMenuInformation: FunctionComponent<MobileMenuInformationType> = ({
  onClose,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const onTabDashboardClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className={styles.mobileMenuInformation} data-animate-on-scroll>
      <div className={styles.nav}>
        <div className={styles.hamburgerMenu} onClick={onClose}>
          <img className={styles.groupIcon} alt="" src="/group1.svg" />
        </div>
        <button className={styles.tabDashboard} onClick={onTabDashboardClick}>
          <img className={styles.tabIcons} alt="" src="/tab-icons11.svg" />
          <div className={styles.dashboard}>Dashboard</div>
        </button>
        <button className={styles.tabDashboard}>
          <img className={styles.tabIcons} alt="" src="/tab-icons11.svg" />
          <div className={styles.dashboard}>Tours</div>
        </button>
        <div className={styles.tabInformation}>
          <div className={styles.itemGroup}>
            <img className={styles.tabIcons} alt="" src="/tab-icons8.svg" />
            <div className={styles.dashboard2}>Information</div>
          </div>
        </div>
        <button className={styles.tabDashboard}>
          <img className={styles.tabIcons} alt="" src="/tab-icons12.svg" />
          <div className={styles.dashboard}>Account</div>
        </button>
      </div>
    </div>
  );
};

export default MobileMenuInformation;
