import { FunctionComponent, useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  TextField,
  FormControlLabel,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icon,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select,
  Alert,
  ThemeProvider,
  CircularProgress,
  createTheme,
} from "@mui/material";
import styles from "./DesktopToursDetailView.module.css";
import DesktopClicksToursDetailView from "./DesktopClicksToursDetailView";
var cloneDeep = require("lodash.clonedeep");

type DesktopToursDetailViewType = {
  onClose?: () => void;
  account: any;
  setAccount: any;
  tourIndex: any;
  script: string;
  discardChanges: any;
  saveAccount: any;
};

const DesktopToursDetailView: FunctionComponent<DesktopToursDetailViewType> = ({
  onClose,
  account,
  setAccount,
  tourIndex,
  script,
  discardChanges,
  saveAccount,
}) => {
  const [lastSavedTour, setLastSavedTour] = useState({});

  useEffect(() => {
    setLastSavedTour(cloneDeep(account.tours[tourIndex ?? 0]));
  }, []);

  const deleteTour = () => {
    var clone = cloneDeep(account);
    clone.tours.splice(tourIndex ?? 0, 1);
    saveAccount(clone).then((result: any) => {
      setAccount(clone);
    });
  };

  const deleteTourStep = (index: number) => {
    account.tours[tourIndex].tourSteps.splice(index ?? 0, 1);
    setAccount(account);
    setSaveClass(saveAnimation.slideup);
    setSaveBanner("");
  };

  //TODO: redo all this nasty. ONLY save account on delete tour and save tour
  //set a formstate for tour on this page, default to account (spread it) and update that.
  const discardTourChanges = () => {
    account.tours[tourIndex ?? 0] = lastSavedTour;
    setAccount(account);
    setLastSavedTour(cloneDeep(lastSavedTour));
    setSaveClass(saveAnimation.slidedown);
    setErrorBanner("");
  };

  const copyScript = () => {
    navigator.clipboard.writeText(script);
  };

  const copyStartButton = () => {
    navigator.clipboard.writeText("introKitStartTour");
  };

  const addStep = () => {
    account.tours[tourIndex ?? 0].tourSteps.push({
      modalText: "",
      modalTitle:
        "New Step" + (account.tours[tourIndex ?? 0].tourSteps.length + 1),
      querySelectorType: "",
      querySelectorInnerText: "",
      id: "",
      cssClass: "",
      clicks: [
        {
          selectionType: "",
          querySelectorType: "",
          querySelectorInnerText: "",
          id: "",
          cssClass: "",
          clickId: new Date().getTime(),
        },
      ],
      clicksReqiured: false,
      url: "",
      selectionType: "",
      tourStepId: new Date().getTime(),
    });
    setTour("tourSteps", account.tours[tourIndex ?? 0].tourSteps);
  };

  const [errorBanner, setErrorBanner] = useState("");
  const [saveBanner, setSaveBanner] = useState("");
  const onCloseButton = () => {
    if (typeof onClose === "function" && saveClass !== saveAnimation.slideup) {
      setErrorBanner("");
      onClose();
    } else {
      setErrorBanner("Please save or discard your changes.");
    }
  };

  enum saveAnimation {
    hidden,
    slideup,
    slidedown,
  }

  const [saveClass, setSaveClass] = useState(saveAnimation.hidden);
  const setTour = (prop: string, value: any) => {
    account.tours[tourIndex ?? 0][prop] = value;
    setAccount(account);
    setSaveClass(saveAnimation.slideup);
    setSaveBanner("");
  };

  const setTourStep = (index: number, prop: string, value: any) => {
    account.tours[tourIndex ?? 0].tourSteps[index][prop] = value;
    setAccount(account);
    setSaveClass(saveAnimation.slideup);
    setSaveBanner("");
  };

  const [disableSave, setDisableSave] = useState(false);
  const save = () => {
    if (
      account.tours[tourIndex].tourTitle === "" ||
      account.tours[tourIndex ?? 0].tourSteps.some((step: any) => {
        return step.modalTitle === "";
      }) ||
      account.tours[tourIndex ?? 0].tourSteps.some((step: any) => {
        if (
          step.selectionType === "Select by HTML Content" &&
          (step.querySelectorType === "" || step.querySelectorInnerText === "")
        ) {
          return true;
        } else if (
          step.selectionType === "Select By CSS Class" &&
          step.cssClass === ""
        ) {
          return true;
        } else if (
          step.selectionType === "Select By Element ID" &&
          step.id === ""
        ) {
          return true;
        }
        return step.clicks.some((click: any) => {
          if (click.selectionType === "Select by HTML Content") {
            return (
              click.querySelectorType === "" ||
              click.querySelectorInnerText === ""
            );
          } else if (click.selectionType === "Select By CSS Class") {
            return click.cssClass === "";
          } else if (click.selectionType === "Select By Element ID") {
            return click.id === "";
          }
        });
      })
    ) {
      return;
    }
    setDisableSave(true);
    saveAccount(account).then((result: any) => {
      if (result.account === "updated") {
        setAccount(account);
        setErrorBanner("");
        setSaveBanner("Saved!");
        setSaveClass(saveAnimation.slidedown);
        setLastSavedTour(cloneDeep(account.tours[tourIndex ?? 0]));
        setDisableSave(false);
      }
    });
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    account.tours[tourIndex].tourSteps = reorder(
      account.tours[tourIndex].tourSteps,
      result.source.index,
      result.destination.index
    );

    setAccount(account);
    setSaveClass(saveAnimation.slideup);
    setSaveBanner("");
  };

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const grid = 8;
  const getItemStyle = (isDragging: Boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: "2px 0",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    background: "#fef7ff",
    width: "100%",
    padding: 0,
  });

  const theme = createTheme({
    palette: {
      primary: {
        main: "#8252d1",
      },
    },
  });

  return (
    <div className={styles.desktopToursDetailView}>
      <div className={styles.header}>
        <div className={styles.howToCreate}>
          {account.tours[tourIndex].tourTitle}
        </div>
        <img
          className={styles.closeicon}
          alt=""
          src="/closeicon.svg"
          onClick={onCloseButton}
        />
      </div>
      <div className={styles.appWindow}>
        {errorBanner !== "" && (
          <Alert style={{ width: "100%", marginTop: "0" }} severity={"error"}>
            {errorBanner}
          </Alert>
        )}
        {saveBanner !== "" && (
          <Alert style={{ width: "100%", marginTop: "0" }} severity={"success"}>
            {saveBanner}
          </Alert>
        )}
        <div className={styles.controls}>
          <TextField
            className={styles.textfield}
            sx={{ width: 400 }}
            color="primary"
            variant="outlined"
            type="text"
            label="Tour Title"
            size="medium"
            margin="none"
            onChange={(e) => {
              setTour("tourTitle", e.target.value);
            }}
            value={account.tours[tourIndex].tourTitle}
            error={account.tours[tourIndex].tourTitle === ""}
            helperText={
              account.tours[tourIndex].tourTitle === ""
                ? "Title is required"
                : ""
            }
          />
          <FormControlLabel
            label="Enable Tour"
            control={
              <Switch
                color="primary"
                size="medium"
                onChange={(e) => {
                  setTour("enabled", e.target.checked);
                }}
                checked={account.tours[tourIndex].enabled}
              />
            }
          />
          <button className={styles.button} onClick={copyScript}>
            <div className={styles.stateLayer}>
              <img
                className={styles.handleIcon}
                alt=""
                src="/copy-grid-plus-2.svg"
              />
              <div className={styles.labelText}>
                {script.substring(0, 59) + "..."}
              </div>
            </div>
          </button>
          <div className={styles.helperText}>
            <b>*Required*</b> Paste this script into the header of each page of
            the tour
          </div>
          {/* <FormControlLabel
            label="Use LaunchTour Styles"
            control={<Switch color="primary" size="medium" />}
          /> */}
          {/* <div className={styles.addButtonToPage}>
            <FormControlLabel
              label="Add button to page"
              control={<Switch color="primary" size="medium" />}
            />

            <TextField
              className={styles.textfield1}
              color="primary"
              variant="outlined"
              defaultValue="introKitStartTour"
              type="text"
              label="Launch Button Class"
              placeholder="Placeholder"
              
              size="medium"
              margin="none"
              disabled
            />
          </div> */}
          <button className={styles.button} onClick={copyStartButton}>
            <div className={styles.stateLayer}>
              <img
                className={styles.handleIcon}
                alt=""
                src="/copy-grid-plus-2.svg"
              />
              <div className={styles.labelText}>{"introKitStartTour"}</div>
            </div>
          </button>
          <div className={styles.helperText}>
            Add this class to buttons on your site to start the tour, otherwise
            the default tour button will show in upper right
          </div>
          <TextField
            className={styles.textfield}
            sx={{ width: 400 }}
            color="primary"
            variant="outlined"
            type="text"
            label="Tour Primary Color"
            placeholder="#8252d1"
            size="medium"
            margin="none"
            onChange={(e) => {
              setTour("primaryColor", e.target.value);
            }}
            value={account.tours[tourIndex].primaryColor}
            helperText="This will set the color of the buttons in tour window."
          />
        </div>
        <div className={styles.content}>
          <div className={styles.howToCreate}>Steps</div>
          <div className={styles.labelTextButton} onClick={addStep}>
            + Add a Step
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {account.tours[tourIndex ?? 0].tourSteps.map(
                    (tourStep: any, index: number) => {
                      return (
                        <Draggable
                          draggableId={tourStep.tourStepId.toString()}
                          key={tourStep.tourStepId}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <Accordion
                                key={tourStep.tourStepId}
                                className={styles.accordion}
                              >
                                <AccordionSummary
                                  expandIcon={<Icon>expand_more</Icon>}
                                >
                                  <Typography
                                    component={"span"}
                                    sx={{ width: "80%", flexShrink: 0 }}
                                  >
                                    <div className={styles.container}>
                                      <img
                                        className={styles.handleIcon}
                                        alt=""
                                        src="/handle.svg"
                                        {...provided.dragHandleProps}
                                      />
                                      <div className={styles.body1}>
                                        {
                                          account.tours[tourIndex ?? 0]
                                            .tourSteps[index].modalTitle
                                        }
                                      </div>
                                    </div>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography component={"span"}>
                                    <div className={styles.accordiondetails}>
                                      <div className={styles.navigation}>
                                        <div className={styles.typography}>
                                          <b className={styles.body11}>
                                            Navigation
                                          </b>
                                        </div>
                                        <div className={styles.content2}>
                                          <TextField
                                            className={styles.textfield2}
                                            color="primary"
                                            variant="outlined"
                                            type="text"
                                            label="Page URL"
                                            //placeholder="Placeholder"
                                            helperText="Enter the url or relative path of the page this step needs to take place on."
                                            size="medium"
                                            margin="none"
                                            onChange={(e) => {
                                              setTourStep(
                                                index,
                                                "url",
                                                e.target.value
                                              );
                                            }}
                                            value={
                                              account.tours[tourIndex]
                                                .tourSteps[index].url
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className={styles.navigation}>
                                        <div className={styles.typography}>
                                          <b className={styles.body11}>
                                            Clicks
                                          </b>
                                        </div>
                                        <div className={styles.content2}>
                                          <FormControlLabel
                                            label="Simulate Clicks"
                                            control={
                                              <Switch
                                                color="primary"
                                                size="medium"
                                                onChange={(e) => {
                                                  setTourStep(
                                                    index,
                                                    "clicksReqiured",
                                                    e.target.checked
                                                  );
                                                }}
                                                checked={
                                                  account.tours[tourIndex]
                                                    .tourSteps[index]
                                                    .clicksReqiured
                                                }
                                              />
                                            }
                                          />
                                          {account.tours[tourIndex].tourSteps[
                                            index
                                          ].clicksReqiured && (
                                            <>
                                              <hr />
                                              <DesktopClicksToursDetailView
                                                clicks={
                                                  account.tours[tourIndex]
                                                    .tourSteps[index].clicks
                                                }
                                                account={account}
                                                setTourStep={setTourStep}
                                                tourIndex={tourIndex}
                                                tourStepIndex={index}
                                              />
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      <div className={styles.navigation}>
                                        <div className={styles.typography}>
                                          <b className={styles.body11}>
                                            Highlight Targeting
                                          </b>
                                        </div>
                                        <div className={styles.content2}>
                                          <FormControl
                                            className={styles.select}
                                            variant="outlined"
                                          >
                                            <InputLabel color="primary">
                                              Selection Method
                                            </InputLabel>
                                            <Select
                                              color="primary"
                                              name="Selection Method"
                                              size="medium"
                                              label="Selection Method"
                                              onChange={(e) => {
                                                setTourStep(
                                                  index,
                                                  "selectionType",
                                                  e.target.value
                                                );
                                              }}
                                              value={
                                                account.tours[tourIndex]
                                                  .tourSteps[index]
                                                  .selectionType
                                              }
                                            >
                                              <MenuItem value="Select By Element ID">
                                                Select By Element ID
                                              </MenuItem>
                                              <MenuItem value="Select By CSS Class">
                                                Select By CSS Class
                                              </MenuItem>
                                              <MenuItem value="Select by HTML Content">
                                                Select by HTML Content
                                              </MenuItem>
                                            </Select>
                                            <FormHelperText>
                                              Choose the way you would like to
                                              target your element for this step.
                                            </FormHelperText>
                                          </FormControl>
                                          {account.tours[tourIndex].tourSteps[
                                            index
                                          ].selectionType ===
                                            "Select By Element ID" && (
                                            <TextField
                                              className={styles.textfield2}
                                              color="primary"
                                              variant="outlined"
                                              type="text"
                                              label="Target ID"
                                              //placeholder="Placeholder"

                                              size="medium"
                                              margin="none"
                                              onChange={(e) => {
                                                setTourStep(
                                                  index,
                                                  "id",
                                                  e.target.value
                                                );
                                              }}
                                              value={
                                                account.tours[tourIndex]
                                                  .tourSteps[index].id
                                              }
                                              error={
                                                account.tours[tourIndex ?? 0]
                                                  .tourSteps[index]
                                                  .selectionType ===
                                                  "Select By Element ID" &&
                                                account.tours[tourIndex ?? 0]
                                                  .tourSteps[index].id === ""
                                              }
                                              // prettier-ignore
                                              helperText={
                                                (account.tours[tourIndex ?? 0]
                                                  .tourSteps[index]
                                                  .selectionType ===
                                                  "Select By Element ID" &&
                                                account.tours[tourIndex ?? 0]
                                                  .tourSteps[index]
                                                  .id === "")
                                                  ? "Target ID is required"
                                                  : "Enter the ID of the element you want to highlight. You can add an ID to the element if it doesn't have one, or choose a different way to target. Remember, elements can have only one ID and each ID can only be used once on each page."
                                              }
                                            />
                                          )}
                                          {account.tours[tourIndex].tourSteps[
                                            index
                                          ].selectionType ===
                                            "Select By CSS Class" && (
                                            <TextField
                                              className={styles.textfield2}
                                              color="primary"
                                              variant="outlined"
                                              type="text"
                                              label="CSS Class"
                                              //placeholder="Placeholder"

                                              size="medium"
                                              margin="none"
                                              onChange={(e) => {
                                                setTourStep(
                                                  index,
                                                  "cssClass",
                                                  e.target.value
                                                );
                                              }}
                                              value={
                                                account.tours[tourIndex]
                                                  .tourSteps[index].cssClass
                                              }
                                              error={
                                                account.tours[tourIndex ?? 0]
                                                  .tourSteps[index]
                                                  .selectionType ===
                                                  "Select By CSS Class" &&
                                                account.tours[tourIndex ?? 0]
                                                  .tourSteps[index].cssClass ===
                                                  ""
                                              }
                                              // prettier-ignore
                                              helperText={
                                                (account.tours[tourIndex ?? 0]
                                                  .tourSteps[index]
                                                  .selectionType ===
                                                  "Select By CSS Class" &&
                                                account.tours[tourIndex ?? 0]
                                                  .tourSteps[index]
                                                  .cssClass === "")
                                                  ? "CSS Class is required"
                                                  : "Enter a css class name assigned to the element you want to highlight. HTML elements can have multiple classes and classes can also be applied to multiple objects. Because the first element with your class name will be targeted we recommend creating and adding a unique class name for each step."
                                              }
                                            />
                                          )}
                                          {account.tours[tourIndex].tourSteps[
                                            index
                                          ].selectionType ===
                                            "Select by HTML Content" && (
                                            <>
                                              <hr />
                                              <TextField
                                                className={styles.textfield2}
                                                color="primary"
                                                variant="outlined"
                                                type="text"
                                                label="Target Parent Tag Type"
                                                //placeholder="Placeholder"
                                                size="medium"
                                                margin="none"
                                                onChange={(e) => {
                                                  setTourStep(
                                                    index,
                                                    "querySelectorType",
                                                    e.target.value
                                                  );
                                                }}
                                                value={
                                                  account.tours[tourIndex]
                                                    .tourSteps[index]
                                                    .querySelectorType
                                                }
                                                error={
                                                  account.tours[tourIndex ?? 0]
                                                    .tourSteps[index]
                                                    .selectionType ===
                                                    "Select by HTML Content" &&
                                                  account.tours[tourIndex ?? 0]
                                                    .tourSteps[index]
                                                    .querySelectorType === ""
                                                }
                                                // prettier-ignore
                                                helperText={
                                                  (account.tours[tourIndex ?? 0]
                                                    .tourSteps[index]
                                                    .selectionType ===
                                                    "Select by HTML Content" &&
                                                  account.tours[tourIndex ?? 0]
                                                    .tourSteps[index]
                                                    .querySelectorType === "")
                                                    ? "Target Parent Tag Type is required"
                                                    : "Enter the tag type of the parent element of your desired target."
                                                }
                                              />
                                              <TextField
                                                className={styles.textfield2}
                                                color="primary"
                                                variant="outlined"
                                                type="text"
                                                label="Target Inner Html"
                                                //placeholder="Placeholder"
                                                size="medium"
                                                margin="none"
                                                onChange={(e) => {
                                                  setTourStep(
                                                    index,
                                                    "querySelectorInnerText",
                                                    e.target.value
                                                  );
                                                }}
                                                value={
                                                  account.tours[tourIndex]
                                                    .tourSteps[index]
                                                    .querySelectorInnerText
                                                }
                                                error={
                                                  account.tours[tourIndex ?? 0]
                                                    .tourSteps[index]
                                                    .selectionType ===
                                                    "Select by HTML Content" &&
                                                  account.tours[tourIndex ?? 0]
                                                    .tourSteps[index]
                                                    .querySelectorInnerText ===
                                                    ""
                                                }
                                                // prettier-ignore
                                                helperText={
                                                  (account.tours[tourIndex ?? 0]
                                                    .tourSteps[index]
                                                    .selectionType ===
                                                    "Select by HTML Content" &&
                                                  account.tours[tourIndex ?? 0]
                                                    .tourSteps[index]
                                                    .querySelectorInnerText ===
                                                    "")
                                                    ? "Target Inner Html is required"
                                                    : "Enter some unique text or html contained by target element. The first instance of an element containing this text or html (with a parent matching the parent tag type) will be targeted."
                                                }
                                              />
                                              <hr />
                                              <div
                                                className={styles.helperText}
                                              >
                                                <b>Example</b>
                                                <br />
                                                {`Given: <li><a>About My Site</a></li>`}
                                                <br />
                                                {`To target the a tag, the parent tag type would be "li" and the inner html could be "About My Site".`}
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      <div className={styles.navigation}>
                                        <div className={styles.typography}>
                                          <b className={styles.body11}>Media</b>
                                        </div>

                                        <div className={styles.content2}>
                                          <TextField
                                            className={styles.textfield2}
                                            color="primary"
                                            variant="outlined"
                                            type="text"
                                            label="Title"
                                            //placeholder="Placeholder"
                                            size="medium"
                                            margin="none"
                                            value={
                                              account.tours[tourIndex ?? 0]
                                                .tourSteps[index].modalTitle
                                            }
                                            onChange={(e) => {
                                              setTourStep(
                                                index,
                                                "modalTitle",
                                                e.target.value
                                              );
                                            }}
                                            error={
                                              account.tours[tourIndex ?? 0]
                                                .tourSteps[index].modalTitle ===
                                              ""
                                            }
                                            helperText={
                                              account.tours[tourIndex ?? 0]
                                                .tourSteps[index].modalTitle ===
                                              ""
                                                ? "Title is required"
                                                : ""
                                            }
                                          />
                                          <TextField
                                            className={styles.select}
                                            color="primary"
                                            variant="outlined"
                                            //defaultValue="Body Text"
                                            multiline
                                            rows={4}
                                            label="Body Text"
                                            //placeholder="Textarea placeholder"
                                            margin="none"
                                            value={
                                              account.tours[tourIndex ?? 0]
                                                .tourSteps[index].modalText
                                            }
                                            onChange={(e) => {
                                              setTourStep(
                                                index,
                                                "modalText",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <button
                                        className={styles.trashDeleteBin1Parent}
                                        onClick={() => deleteTourStep(index)}
                                      >
                                        <img
                                          className={styles.handleIcon}
                                          alt=""
                                          src="/trash-delete-bin-1.svg"
                                        />
                                        <b className={styles.labelText7}>
                                          DELETE Step
                                        </b>
                                      </button>
                                    </div>
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          )}
                        </Draggable>
                      );
                    }
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div style={{ width: "100%" }}>
          <button
            className={styles.trashDeleteBin1Parent}
            onClick={() => {
              if (window.confirm("Are you sure to delete this tour?")) {
                deleteTour();
              }
            }}
          >
            <img
              className={styles.handleIcon}
              alt=""
              src="/trash-delete-bin-1.svg"
            />
            <b className={styles.labelText7}>DELETE Tour</b>
          </button>
        </div>
      </div>
      <div
        className={
          saveClass == saveAnimation.slideup
            ? styles.saveChanges + " " + styles.saveAnim
            : saveClass == saveAnimation.slidedown
            ? styles.saveChanges
            : styles.hidden
        }
      >
        <div className={styles.buttonParent}>
          <button
            className={styles.button1}
            onClick={save}
            disabled={disableSave}
          >
            <div className={styles.stateLayer1}>
              {!disableSave ? (
                ""
              ) : (
                <div className={styles.icon} style={{ paddingRight: "10px" }}>
                  <ThemeProvider theme={theme}>
                    <CircularProgress size="1rem" color="primary" />
                  </ThemeProvider>
                </div>
              )}
              <div className={styles.labelText8}>Save Changes</div>
            </div>
          </button>
          <button
            className={styles.labelText9}
            onClick={discardTourChanges}
            disabled={disableSave}
          >
            Discard Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default DesktopToursDetailView;
