import { useCallback, useEffect, useState } from "react";

const IsTabActive = () => {
  const [isTabVisible, setIsTabVisible] = useState(true);

  const handleVisibilityChange = useCallback(() => {
    if (isTabVisible != (document.visibilityState === "visible")) {
      setIsTabVisible(document.visibilityState === "visible");
      var token = sessionStorage.getItem("introKitToken") ?? "";
      const payload = atob(token.split(".")[1]);
      const expiration = new Date(JSON.parse(payload).exp * 1000);
      const now = new Date();
      const week = 1000 * 60 * 60 * 18; //milla > seconds > minutes > hours > 18 hours
      if (expiration.getTime() - now.getTime() < week) {
        sessionStorage.clear();
        window.location.replace(
          "http://auth.app.introkit.io/login?response_type=code&client_id=19b7bhnb1uqka7l2dhjn1mub7o&redirect_uri=" +
            getRedirectUri()
        );
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return <></>;
};

export default IsTabActive;

function getRedirectUri() {
  return document.location.toString().includes("3000")
    ? "http%3A%2F%2Flocalhost:3000"
    : "https%3A%2F%2Fapp.introkit.io";
}
