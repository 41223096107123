import { FunctionComponent, useState, useCallback } from "react";
import MobileMenuInformation from "../components/MobileMenuInformation";
import PortalDrawer from "../components/PortalDrawer";
import { useNavigate } from "react-router-dom";
import CardContainer from "../components/CardContainer";
import styles from "./DesktopInformation.module.css";
const DesktopInformation: FunctionComponent = () => {
  const [isMobileMenuInformationOpen, setMobileMenuInformationOpen] =
    useState(false);
  const navigate = useNavigate();

  const openMobileMenuInformation = useCallback(() => {
    setMobileMenuInformationOpen(true);
  }, []);

  const closeMobileMenuInformation = useCallback(() => {
    setMobileMenuInformationOpen(false);
  }, []);

  const onTabDashboardContainerClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onTabToursContainerClick = useCallback(() => {
    navigate("/desktop-tours");
  }, [navigate]);

  const onTabInformationContainerClick = useCallback(() => {
    navigate("/desktop-information");
  }, [navigate]);

  const onTabAccountContainerClick = useCallback(() => {
    navigate("/desktop-account");
  }, [navigate]);

  return (
    <>
      <div className={styles.desktopInformation}>
        <nav
          className={styles.nav}
          id="nav"
          onClick={openMobileMenuInformation}
        >
          <div className={styles.logo}>
            <img className={styles.vectorIcon} alt="" src="/vector10.svg" />
          </div>
          <div className={styles.tabGroup}>
            <div
              className={styles.tabDashboard}
              onClick={onTabDashboardContainerClick}
            >
              <img className={styles.tabIcons} alt="" src="/tab-icons7.svg" />
              <div className={styles.dashboard}>Dashboard</div>
            </div>
            <div
              className={styles.tabDashboard}
              onClick={onTabToursContainerClick}
            >
              <img className={styles.tabIcons} alt="" src="/tab-icons7.svg" />
              <div className={styles.dashboard}>Tours</div>
            </div>
            <div
              className={styles.tabInformation}
              onClick={onTabInformationContainerClick}
            >
              <img className={styles.tabIcons2} alt="" src="/tab-icons8.svg" />
              <div className={styles.dashboard2}>Information</div>
              <div className={styles.tabInformationChild} />
            </div>
            <div
              className={styles.tabDashboard}
              onClick={onTabAccountContainerClick}
            >
              <img className={styles.tabIcons} alt="" src="/tab-icons9.svg" />
              <div className={styles.dashboard}>Account</div>
            </div>
          </div>
          <div className={styles.hamburgerMenu}>
            <img className={styles.groupIcon} alt="" src="/group3.svg" />
          </div>
        </nav>
        <div className={styles.appWindow}>
          <div className={styles.informationParent}>
            <div className={styles.information}>INFORMATIon</div>
            <div className={styles.information}>/</div>
          </div>
          <div className={styles.header}>
            <b className={styles.information1}>Information</b>
          </div>
          <div className={styles.contentParent}>
            <div className={styles.content}>
              <div className={styles.stackedCard}>
                <div className={styles.cardStateLayeroutlined}>
                  <div className={styles.stateLayer} />
                </div>
                <div className={styles.contentContainer}>
                  <div className={styles.mediaTextContent}>
                    <img
                      className={styles.mediaIcon}
                      alt=""
                      src="/media@2x.png"
                    />
                    <div className={styles.textContent}>
                      <div className={styles.headline}>
                        <div className={styles.title}>Title</div>
                        <div className={styles.subhead}>Subhead</div>
                      </div>
                      <div className={styles.subhead}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor
                      </div>
                      <button className={styles.actions}>
                        <div className={styles.secondaryButton}>
                          <div className={styles.stateLayer1}>
                            <div className={styles.labelText}>Enabled</div>
                          </div>
                        </div>
                        <div className={styles.primaryButton}>
                          <div className={styles.stateLayer1}>
                            <div className={styles.labelText1}>Enabled</div>
                          </div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.stackedCard1}>
                <div className={styles.cardStateLayeroutlined1}>
                  <div className={styles.stateLayer} />
                </div>
                <div className={styles.stackedCard2}>
                  <div className={styles.cardStateLayeroutlined}>
                    <div className={styles.stateLayer} />
                  </div>
                  <div className={styles.contentContainer}>
                    <div className={styles.mediaTextContent}>
                      <img
                        className={styles.mediaIcon}
                        alt=""
                        src="/media@2x.png"
                      />
                      <div className={styles.textContent}>
                        <div className={styles.headline1}>
                          <div className={styles.title}>Title</div>
                          <div className={styles.subhead}>Subhead</div>
                        </div>
                        <div className={styles.supportingText1}>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor
                        </div>
                        <div className={styles.actions1}>
                          <div className={styles.secondaryButton}>
                            <div className={styles.stateLayer1}>
                              <div className={styles.dashboard}>Enabled</div>
                            </div>
                          </div>
                          <div className={styles.primaryButton1}>
                            <div className={styles.stateLayer1}>
                              <div className={styles.dashboard}>Enabled</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CardContainer />
            </div>
            <div className={styles.content1}>
              <CardContainer />
              <div className={styles.stackedCard3}>
                <div className={styles.cardStateLayeroutlined1}>
                  <div className={styles.stateLayer} />
                </div>
                <div className={styles.stackedCard2}>
                  <div className={styles.cardStateLayeroutlined}>
                    <div className={styles.stateLayer} />
                  </div>
                  <div className={styles.contentContainer}>
                    <div className={styles.mediaTextContent}>
                      <img
                        className={styles.mediaIcon}
                        alt=""
                        src="/media@2x.png"
                      />
                      <div className={styles.textContent}>
                        <div className={styles.headline1}>
                          <div className={styles.title}>Title</div>
                          <div className={styles.subhead}>Subhead</div>
                        </div>
                        <div className={styles.supportingText1}>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor
                        </div>
                        <div className={styles.actions1}>
                          <div className={styles.secondaryButton}>
                            <div className={styles.stateLayer1}>
                              <div className={styles.dashboard}>Enabled</div>
                            </div>
                          </div>
                          <div className={styles.primaryButton1}>
                            <div className={styles.stateLayer1}>
                              <div className={styles.dashboard}>Enabled</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CardContainer />
            </div>
          </div>
        </div>
      </div>
      {isMobileMenuInformationOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Right"
          onOutsideClick={closeMobileMenuInformation}
        >
          <MobileMenuInformation onClose={closeMobileMenuInformation} />
        </PortalDrawer>
      )}
    </>
  );
};

export default DesktopInformation;
